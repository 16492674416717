import { useEffect, useState } from 'react';
import React from 'react';
import './ProjectShowcase.css'
import Card from '../card/Card';
import { render } from '@testing-library/react';
import { call, func } from 'three/examples/jsm/nodes/Nodes.js';

const ProjectShowcase = (props) => {

  const [projects, setProjects] = useState([])
  const [categories, setCategories] = useState([])
  const [selected, setSelected] = useState([])


  useEffect(() => {
    setProjects(props.json)
    setCategories(["Cybersecurity","Web development","Software development","Artificial Intelligence","Mobile development","Middleware","Operating System","Mathematics"])

  }, [selected])
  
  var UIcards = projects.map((p)=>{

    if(selected.includes(p.category) | selected.length == 0){
      return(
        <Card title={p.title} img={p.image} content={p.content} techno={p.techno}/>
      )
    }else{
      return(<></>)
    }
    

    })

  var UIbuttons = categories.map(
    (text,index)=>{

    function callbackOnClick(e,keyval){
      if(selected.includes(keyval)){
        var index = selected.indexOf(keyval)
        if(index !== -1){
          const new_array = [...selected]
          new_array.splice(index,1)
          setSelected(new_array)
        }
      }else{
        var new_array = [...selected]
        new_array.push(keyval)
        setSelected(new_array)
      }
    }

      return(
        <button type='button' className={selected.includes(text)? 'button-filter-selected':'button-filter'} onClick={(e)=>{callbackOnClick(e,text)}}>{text}</button>
      )
    }
  )



  return (
    <>
    <div className='filter-button-container'>
      {UIbuttons}
    </div>
    <div className='card-showcase-container'>
      {UIcards}
    </div>
    </>
  );
};

export default ProjectShowcase;
