import React from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import '../header/Header.css'


function Header() {
  const navigate = useNavigate();

  const routeChange1 = () =>{ 
    navigate(`/engineering-course`);
    setTimeout(function () {
      window.scrollTo(0,200);
    },200);
  }

  const routeChange2 = () =>{ 
    navigate(`/engineering-course`);
    setTimeout(function () {
      window.scrollTo(0,1700);
    },200);
  }
  const routeChange3 = () =>{ 
    navigate(`/engineering-course`);
    setTimeout(function () {
      window.scrollTo(0,200);
    },200);
  }

  const routeChange4 = () =>{
    navigate(`/engineering-course`);
    setTimeout(function () {
      window.scrollTo(0,1295);
    },200);
  }

  const routeChange5 = () =>{
    navigate(`/engineering-course`);
    setTimeout(function () {
      window.scrollTo(0,2515);
    },200);
  }

  return (
    <div className='header-container'>
      <h1 id="header-maintitle">Nolan</h1>
      <ul id='header-ul'>
        <li onClick={(e) => e.currentTarget.firstChild.click()}><Link className='header-link' style={{ textDecoration: 'none' }} to="/">Home</Link></li>
        <li onClick={(e) => e.currentTarget.firstChild.click()}>
          <Link className='header-link' style={{ textDecoration: 'none' }} to="/career-development">Background</Link>
          <ul>
            <li onClick={routeChange1}>Professional background</li> 
            <li onClick={routeChange2}>Academic background</li> 
          </ul>
        </li>
        <li onClick={(e) => e.currentTarget.firstChild.click()}>
          <Link className='header-link' style={{ textDecoration: 'none' }} to="/hard-skills">Technical Skills</Link>
          <ul>
            <li onClick={routeChange3}>Projects</li> 
            <li onClick={routeChange4}>Skills</li>
            <li onClick={routeChange5}>Certificates</li>
          </ul>
        </li>
        <li onClick={(e) => e.currentTarget.firstChild.click()}><Link className='header-link' style={{ textDecoration: 'none' }} to="/cv">CV</Link></li>
        <li onClick={(e) => e.currentTarget.firstChild.click()}><Link className='header-link' style={{ textDecoration: 'none' }} to="/sport">Sport & Hobbies</Link></li>
      </ul>
    </div>
  )
}



export default Header