import React, { useEffect, useRef } from 'react';
import './CV.css'
import FrenchCV from "../../pages/cv/CVFR.pdf"
import EnglishCV from "../../pages/cv/CVEN.pdf"

import MainLayout from '../../component/mainlayout/MainLayout';

function CV() {
  // const sceneRef = useRef();
  // const rendererRef = useRef();

//   useEffect(() => {
//     // Set up scene
//     const scene = new THREE.Scene();

//     // Set up camera
//     const camera = new THREE.PerspectiveCamera(50, window.innerWidth / window.innerHeight, 0.1, 1000);
//     camera.position.z = 5;

//     // Set up renderer
//     const renderer = new THREE.WebGLRenderer();
//     renderer.setSize(window.innerWidth, window.innerHeight);
//     sceneRef.current.appendChild(renderer.domElement);
//     rendererRef.current = renderer;

//     // Create central point
//     const centralPointMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff, transparent: false, opacity: 100});
//     const centralPoint = new THREE.Mesh(new THREE.SphereGeometry(0.2), centralPointMaterial);
//     scene.add(centralPoint);

//     // Create planets
//     const planets = [];
//     const orbits = [];
//     const numPlanets = 12;

//     for (let i = 0; i < numPlanets; i++) {
//       const planet = new THREE.Mesh(new THREE.SphereGeometry(0.05), new THREE.MeshBasicMaterial({ color: 0xffffff }));
//       planets.push(planet);

//       // Set up random orbit radius and slower speed
//       const orbitRadius = Math.random() * 8 + 1;
//       const orbitSpeed = Math.random() * 0.0001 + 0.00002;

// // Create orbit circle
// let orbitGeometry = new THREE.BufferGeometry().setFromPoints(
//   new THREE.Path().absarc(0, 0, orbitRadius, 0, Math.PI * 2).getSpacedPoints(128)
// );
// let orbitMaterial = new THREE.LineBasicMaterial({color: "white"});
// const orbit = new THREE.Line(orbitGeometry, orbitMaterial);
// orbits.push(orbit);

// scene.add(planet);
// scene.add(orbit);

// // Update function to animate planets and orbits
// const update = () => {
//   const angle = Date.now() * orbitSpeed;
//   planet.position.x = centralPoint.position.x + orbitRadius * Math.cos(angle);
//   planet.position.y = centralPoint.position.y + orbitRadius * Math.sin(angle);

//   // Update orbit position
//   orbit.position.x = centralPoint.position.x;
//   orbit.position.y = centralPoint.position.y;
  
//   // Update orbit rotation
//   orbit.rotation.z = angle;
// };

//       // Add update function to the animation loop
//       const animate = () => {
//         update();
//         renderer.render(scene, camera);
//         requestAnimationFrame(animate);
//       };

//       animate();
//     }

//     // Handle window resize
//     const handleResize = () => {
//       const newWidth = window.innerWidth;
//       const newHeight = window.innerHeight;

//       camera.aspect = newWidth / newHeight;
//       camera.updateProjectionMatrix();

//       renderer.setSize(newWidth, newHeight);
//     };

//     window.addEventListener('resize', handleResize);
// // Cleanup
// return () => {
//   window.removeEventListener('resize', handleResize);

//   // Remove the renderer's DOM element from the current scene
//   if (sceneRef.current && rendererRef.current) {
//     sceneRef.current.removeChild(rendererRef.current.domElement);
//   }
// };
//   }, []);

  return (
    <MainLayout>
      
      <div className='cv-container'>
=      <div className='cv-text-container'>
        <h1 id="title-cv">You can download my CV</h1>
        <div className='cv-cv-container'>
          <a href={FrenchCV} download="French_CV_CORBELLARI_Nolan" rel="noreferrer"><button >French version</button> </a>
          <a href={EnglishCV} download="English_CV_CORBELLARI_Nolan" rel="noreferrer"><button>English version</button></a>
        </div>
      </div>
      </div>
    </MainLayout>
  );
}

export default CV;
