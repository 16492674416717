import React from 'react'
import MainLayout from '../../component/mainlayout/MainLayout'
import SectionWhite from '../../component/sectionvar-white/SectionWhite'
import SectionBlack from '../../component/sectionvar-black/SectionBlack'
import Img1 from './womenEq.webp'
import Img2 from './cattracker.jpg'
import Img3 from './cap.png'


function CivicEng() {
  const title1 = "Engagement for women equality"
  const title2 = "Attempt to create a sustainable start-up"
  const title3 = "Awareness about disabled people"
  

  const ptext1= `When I was in high school, I participated in a challenge called 'Concours Caroline Aigle', which involved creating a poster to promote the work of women in science. I formed a team with two friends and designed our poster.We were pleasantly surprised to discover that we had won second place in the challenge. Additionally, this year, I enrolled in a play that explains sexual harassment to raise awareness and prevent it.`
  const ptext2 = `In 2023, I lost one of my cats. This made me reflect on how I could have prevented it from happening. 
  This led me to the realization that others may face the same problem, and I could potentially help them. With a partner, I began designing a solution to this issue. However, it took too much time, so we had to stop. This experience was not only interesting for the person I met but also for working independently on a problem that no one had tried to solve.`
  const ptext3 = `In 2023, we participated in Capgemini's 'Positive Challenge', which aimed to facilitate the integration of individuals with disabilities, promote diversity and inclusion in infrastructure. This experience provided us with valuable insights into the lives of disabled individuals.`


  return (
    <MainLayout>
        <SectionWhite title={title1} ptext={ptext1} img={Img1}/>
        <SectionBlack title={title2} ptext={ptext2} img={Img2}/>
        <SectionWhite title={title3} ptext={ptext3} img={Img3}/>
    </MainLayout>
  )
}

export default CivicEng