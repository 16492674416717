import React from 'react'
import "./Card.css"

function Card(props) {
  const url = '../img/'
  const img = url+(props.img)

  const techno = props.techno.map((text)=>{
    return(<a className='techno-card'>{text}</a>)
  })

  return (
    <div className='card-container'>
      <div className='card-image-container'>
        <img id='card-image' src={img} alt="" loading='lazy' />
      </div>
        <h1 id='card-title'>{props.title}</h1>
        <p id='card-content'>{props.content}</p>
        <div className='techno-container'>
          {techno}
        </div>
    </div>
  )
}

export default Card