import React from 'react';
import './CardHome.css';
import { FaShieldAlt } from "react-icons/fa";
import { LuBrainCircuit } from "react-icons/lu";

const ptext1 = `Cybersecurity is a topic I’ve been appreciating for a long time, and now as a third-year engineering student taking the TLS-Sec course, I can finally deepen my knowledge of it. My first experience with cybersecurity was during my preparatory class as I chose the theme "security concerning IoT objects" for my last year project. While being an engineering student, I first completed my first-year internship in a laboratory, and the internship’s theme was  ‘security and safety of critical systems’.  I also developed a program named ‘Plague’ (which can be found on my Github), which can generate a personalized password list following special patterns defined by the user. Finally, this year cybersecurity is at the core of my university program, as my course (TLS-Sec) is dealing with lots of aspects of it.`
const ptext2 = ` I discovered AI during my second-year engineering school program. I had strong mathematical foundations thanks to first-year subjects (such as optimization and introduction to neural networks). During my course we covered subjects such as CNN, neural networks, deep learning, and many others. Apart from school, I also interested myself in NLP, LLM, and fine-tuning them, and I’m currently working on fine tuning an LLM model for my entrepreneurial project Futurapply.`


function CardHome() {
  return (
    <div className="card-container-home">
      <div className="card-home">
        <div className="card-icon-home">
          <FaShieldAlt />
        </div>
        <h2 className="card-title-home">Cybersecurity</h2>
        <p className="card-description-home">{ptext1}</p>
      </div>
      <div className="card-home">
        <div className="card-icon-home">
          <LuBrainCircuit />
        </div>
        <h2 className="card-title-home">Artificial Intelligence</h2>
        <p className="card-description-home">{ptext2}</p>
      </div>
    </div>
  );
}

export default CardHome;
