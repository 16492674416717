import React from 'react'
import MainLayout from '../../component/mainlayout/MainLayout'
import SectionBlack from '../../component/sectionvar-black/SectionBlack'
import SectionWhite from '../../component/sectionvar-white/SectionWhite'
import Img1 from './all.webp'
import Img2 from './en.webp'
import Img3 from './ch.webp'


function InternationalMob() {

const title1 = "Germany"
const title2 = "English" 
const title3 = "Chinese"

const ptext1 = `I'm have been learning German for 12 years now. I started at the age of 7 to learn basic sentences in German then I kept learning German in middle school when I chose the option "Cooltour", which added 2 hours of German courses per week and allowed me to go in Germany for first time at the age of 13. Then I went to Lübeck for a week at the age of 14.

I studied German in high school to get the ABIBAC which combines the French Baccalauréat and the equivalent German one. Thanks to this option, I went to Germany  in Landshut for a school exchange and then in Nuremberg.`
const ptext2 = `I'm pretty familiar with English. I never had the opportunity to visit either the United Kingdom or the United States, however one hobby is reading and I read books only in English, I like watching movies in English too. Playing video games was one of my past hobbies, which gave me the opportunity to meet people from all around the world. This has helped me to speak and improve my English.`
const ptext3 = `As I integrated ENSEEIHT, I wanted to discover a brand new language and after thinking about what could be useful for my future career, I decided to learn Chinese. However at the moment my chinese skills are limited.`


  return (
    <MainLayout>
        <SectionBlack title={title1} ptext={ptext1} img={Img1}/>
        <SectionWhite title={title2} ptext={ptext2} img={Img2}/>
        <SectionBlack title={title3} ptext={ptext3} img={Img3}/>
    </MainLayout>
  )
}

export default InternationalMob