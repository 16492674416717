import React, { useState } from 'react';
import "./Skills.css"

function Skills(){
  const [activeSection, setActiveSection] = useState('Web Language');

  const skillsData = {
    'Web Language': [
      { title: 'HTML', imgSrc:'html.png' },
      { title: 'CSS', imgSrc: 'css.png' },
      { title: 'Javascript', imgSrc: 'js.png' },
      { title: 'React', imgSrc: 'react.svg' },
      { title: 'Electron', imgSrc: 'electron.png' },
    ],
    'Functional Language': [
      { title: 'Ocaml', imgSrc: 'ocaml.png' },
    ],
    'Imperative Language':[
        { title: 'Python', imgSrc: 'python.png' },
        { title: 'Ada', imgSrc: 'ada.svg' },
        { title: 'C', imgSrc: 'c.png' },
        { title: 'Matlab', imgSrc: 'matlab.png' },
        { title: 'Julia', imgSrc: 'julia.png' },
    ],
    'Object-oriented Language':[
        { title: 'Java', imgSrc: 'java.png' },
        { title: 'UML', imgSrc: 'uml.png' },
    ],
    'Modeling Language':[
        { title: 'Eclipse modeling framework', imgSrc: 'emt.jpg' },
        { title: 'OCL', imgSrc: 'eclipse.png' },
        { title: 'ATL', imgSrc: 'atl.jpeg' },
        { title: 'Xtext', imgSrc: 'xtext.webp' },
        { title: 'Acceleo', imgSrc: 'acceleo.png' },
        { title: 'Sirius', imgSrc: 'sirius.png' },
    ],
    'Operating System':[
        { title: 'Bash', imgSrc: 'bash.png' },
    ],
    'Project Management Software':[
        { title: 'Github', imgSrc: 'github.png' },
        { title: 'Trello', imgSrc: 'trello.png' },
        { title: 'Clickup', imgSrc: 'clickup.png' },
    ],
    // Add more sections as needed
  };

  const handleSectionChange = (section) => {
    setActiveSection(section);
  };

  return (
    <div className="skills-container">
      <div className="sidebar">
        {Object.keys(skillsData).map((section) => (
          <div
            key={section}
            className={`sidebar-item ${section === activeSection ? 'active' : ''}`}
            onClick={() => handleSectionChange(section)}
          >
            {section}
          </div>
        ))}
      </div>

      <div className="skills-list">
        {skillsData[activeSection].map((skill, index) => (
          <div key={index} className="skill-card">
            <img src={require(`./img/${skill.imgSrc}`)} loading="lazy" alt={skill.title} />
            <p><b>{skill.title}</b></p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Skills;
