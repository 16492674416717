import React from 'react'
import "./SectionWhite.css"

function SectionWhite(props) {
  return (
    <>
    <div className='sectionWhite-container'>
      <div className='sectionWhite-textcontainer'>
        <h1 id='sectionWhite-title'>{props.title}</h1>
        <p id='sectionWhite-ptext'>{props.ptext}</p>
      </div>
      <img id='sectionWhite-img' loading='lazy' src={props.img}></img>
    </div>
    <div style={{overflow:'hidden'}}>
    <div className='sectionWhite-container-end'><></></div>
    </div>
    </>
  )
}

export default SectionWhite