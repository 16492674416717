import "./App.css";
import Home from "./pages/home/Home";
import EngineeringCourse from "./pages/engCourse/EngineeringCourse";
import CV from "./pages/cv/CV";
import InternationalMob from "./pages/mobility/InternationalMob";
import CivicEng from "./pages/civicEngagement/CivicEng";
import Sport from "./pages/sport/Sport";
import CareerDev from "./pages/careerDev/CareerDev";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ReactLenis, useLenis } from '@studio-freight/react-lenis'


function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "hard-skills",
      element: (
        <div>
          <EngineeringCourse />
        </div>
      ),
    },
    {
      path: "civic-engagement",
      element: (
        <div>
          <CivicEng />
        </div>
      ),
    },
    {
      path: "cv",
      element: (
        <div>
          <CV />
        </div>
      ),
    },
    {
      path: "sport",
      element: (
        <div>
          <Sport />
        </div>
      ),
    },
    {
      path: "career-development",
      element: (
        <div>
          <CareerDev />
        </div>
      ),
    },
  ]);

  return (
    <div className="App">
      <ReactLenis root>
        <RouterProvider router={router} />
      </ReactLenis>
    </div>
  );
}

export default App;
