import React from 'react'
import "./Title.css"

function Title({children}) {
  return (
    <div className='engCourse-title-container'>
        <h1 id='engCourse-title'>{children}</h1>
    </div>
  )
}

export default Title