import React, { useEffect } from "react";
import MainLayout from "../../component/mainlayout/MainLayout";
import "./Home.css";
import { useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SplitType from "split-type";
import Typewriter from "typewriter-effect/dist/core";
import CardHome from "../../component/CardHome/CardHome";

gsap.registerPlugin(ScrollTrigger);

function Home() {
  const container = useRef([]);
  const mainTitle = useRef([]);
  container.current = [];
  mainTitle.current = [];

  const addtoRefs = (el) => {
    if (el && !container.current.includes(el)) {
      container.current.push(el);
    }
  };
  const addtoRefs2 = (el) => {
    if (el && !mainTitle.current.includes(el)) {
      mainTitle.current.push(el);
    }
  };

  useEffect(() => {
    new Typewriter("#home-headpage-fsttext", {
      strings: "CORBELLARI Nolan",
      autoStart: true,
      // cursor : "_"
    });

    // container.current.forEach((char,i)=>{
    //     const text = new SplitType(char,{ types:'char,words'})
    //     gsap.from(text.chars,{
    //     scrollTrigger:{
    //         trigger:char,
    //         start: 'top 80%',
    //         end: 'end 50%',
    //       },
    //       y:-20,
    //       scaleY:0,
    //       stagger:0.005,
    //       duration: 0.2
    //     })
    //   })

    mainTitle.current.forEach((char, i) => {
      const text = new SplitType(char, { types: "char,words" });
      gsap.from(text.chars, {
        scrollTrigger: {
          trigger: char,
          start: "top 80%",
          end: "end 50%",
        },
        opacity: 0,
        duration: 0.4,
        stagger: 0.2,
      });
    });
  }, []);

  const ptext1 = `My name is Nolan CORBELLARI. I’m a third-year computer science engineering student at the ENSEEIHT, member of the Institut National Polytechnique (“INP”) located in Toulouse. Cybersecurity and AI are subject-matters of particular interest to me. Therefore, I have applied for and I have been accepted in a selective section called TLS-sec, labelled “Secnumedu” and fully acknowledged by the ANSSI. Curiosity, perseverance and autonomy are personal qualities that helped me reaching this level and I’m keen on experiencing these softskills from a professional perspective in a very near future.`;
  const ptext2 = "Welcome to my portfolio. Thank you for taking time to visit it. On this website you can find more information about my situation, my goals and my background. You can also find information about my hard and soft skills, especially technical skills related to IT or even linguistic skills. Finally if this website does not have the piece of  information you’re looking for or if you need more information about me you can contact me at this address : corbellari.nolan@gmail.com"
  const ptext3 = `My experience leading various projects has given me the ability to be a good listener and empathetic towards others.I am dedicated to my work, but I also understand the importance of communication within a team. 
  When a situation becomes critical, I can remain calm and make rational decisions.Additionally, I am adaptable and versatile within a team, able to take on technical or leadership roles as needed to benefit the project.`;

  return (
    <MainLayout>
      <div className="home-headpage-container">
        <div className="home-section-container">
          <div id="home-headpage-leftdiv">
            <h1 id="home-headpage-fsttext">CORBELLARI Nolan</h1>
            <div className="container">
              <h2 id="home-headpage-sndtext" ref={addtoRefs}>
                {" "}
                Third year computer science engineering student specialized in
                cybersecurity (TLS-Sec){" "}
              </h2>
            </div>
          </div>
          <div id="home-headpage-rightdiv">
            <div className="home-headpage-img-container">
              {/* <img
                id="home-headpage-img"
                loading="lazy"
                src={require(`../../component/card/test.jpg`)}
                alt=""
              /> */}
            </div>
          </div>
        </div>
        <div className="home-aboutme-container">
          <div className="home-aboutme-banner">
            <h1 id="home-aboutme-title" className="text" ref={addtoRefs2}>
              {" "}
              Presentation
            </h1>
          </div>
          <div className="about-me-div">
            <p id="home-aboutme-ptext" className="text" ref={addtoRefs}>
              {ptext1}
            </p>
          </div>
          <h1 className="home-aboutme-ptext-title" ref={addtoRefs2}>
            This portfolio
          </h1>
          <div className="about-me-div">
            <p id="home-aboutme-ptext" className="text" ref={addtoRefs}>
              {ptext2}
            </p>
          </div>
          <h1 className="home-aboutme-ptext-title" ref={addtoRefs2}>
              My interests
          </h1>
          <CardHome/>
          {/* <div className="about-me-div">
            <p id="home-aboutme-ptext" className="text" ref={addtoRefs}>
              {" "}
              {ptext3}
            </p>
          </div> */}
        </div>
      </div>
    </MainLayout>
  );
}

export default Home;
