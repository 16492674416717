import React from "react";
import ListContainer from "../../component/listContainer/ListContainer";
import MainLayout from "../../component/mainlayout/MainLayout";
import Skills from "../../component/skills/Skills";
import Title from "../../component/title/Title";
import Certificate from "../../component/certificate/Certificate";
import "./EngineeringCourse.css";
import projects from "./projects.json";
import ProjectShowcase from "../../component/ProjectShowcase/ProjectShowcase";

function EngineeringCourse() {
  return (
    <MainLayout>
      <Title>Projects</Title>
      <ProjectShowcase json={projects} />
      <Title>Skills</Title>
      <Skills />
      <Title>Certificates</Title>
      <Certificate />
    </MainLayout>
  );
}

export default EngineeringCourse;
