import React from "react";
import Footer from "../footer/Footer";
import Header from "../header/Header";
import "./MainLayout.css";


function MainLayout({children}){

    
    return(
        <>        
        
        <Header id="MainHeader"/>
        <div className="mainlayout-container" >
        {children}
        </div>
        <Footer id="MainFooter"/>
        
        </>
    )
}
export default MainLayout;


