import React from 'react'
import MainLayout from '../../component/mainlayout/MainLayout'
import SectionBlack from '../../component/sectionvar-black/SectionBlack'
import SectionWhite from '../../component/sectionvar-white/SectionWhite'
import Img1 from '../sport/piano.jpg'
import Img2 from '../sport/boxe.jpg'

function Sport() {
  const title1 = "Music"
  const title2 = "Martial Arts"

  const ptext1= `Outside of school I love to play music, especially playing piano.

  I started to learn this instrument at the age of six, thanks to my mom who taught me to read music sheets.
  
  At eleven, I’ve been learning during two years drums and percussions
  
  Later, at 17, I decided to improve my piano skills by taking piano lessons with a professor.
  
  Learning piano taught me patience, perseverance and consistency. 
  `
  const ptext2 = `Sports have always been present in my life.

  I started doing Judo at the age of 5 and began competing at the age of 8. I was pretty active in competitions for about two years. Then, I decided to switch things up and try a different martial art that better fit my schedule, so I took up Aïkido and stuck with it for four years.
    During my preparatory class, it was tough to schedule sport sessions, but I made an effort to reserve Wednesdays for running sessions.
    Now, I'm into four different martial arts – boxing, muay thai, krav maga, and MMA.
  Martial arts taught me to challenge myself and push the boundaries.
  `

  return (
    <MainLayout>
        <SectionWhite title={title1} ptext={ptext1} img={Img1}/>
        <SectionBlack title={title2} ptext={ptext2} img={Img2}/>
    </MainLayout>
  )
}

export default Sport