import { useState } from 'react';
import pdfFile from './props/attestation_ANSSI.pdf';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { Viewer,Worker } from '@react-pdf-viewer/core';
import './Certificate.css'

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

function Certificate() {


    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    return (
      <div id='pdf-container'>
        <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.4.120/pdf.worker.min.js">
        <Viewer
        fileUrl={pdfFile}
        defaultScale={1.08}
        plugins={[defaultLayoutPluginInstance]}/>
        </Worker>
      </div>
    );
}

export default Certificate;
