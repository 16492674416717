import React from 'react'
import MainLayout from '../../component/mainlayout/MainLayout'
import SectionRight from '../../component/section-right/SectionRight'
import Section from '../../component/section/Section'
import Img from './irit.webp'
import Img2 from './husky.webp'
import Img3 from './futurapply.png'
import Title from '../../component/title/Title'
import SectionBlack from '../../component/sectionvar-black/SectionBlack'
import SectionWhite from '../../component/sectionvar-white/SectionWhite'
import "./CareerDev.css"
import internReportFr from './Rapport_Stage_fr.pdf'
import internReportEn from './Rapport_Stage_en.pdf'

function CareerDev() {


  const ptext7  = `Summer 2024 I completed my 4-month internship by working on my own project Futurapply, which is the start-up we are founding with SCHEYDER Claire. 

  Futurapply consists of a platform where you can manage, organize, produce and export your cover letter. During those 4 months we created the front and back end servers using React and Django respectively. We worked with Jira and with agile methodology. Finally we are developing an AI to generate a cover letter taking into account the user’s background.
  
  Beyond technical aspects of the project, we improved our problem-solving skill as we needed to solve technical problems by ourselves. Moreover we honed our organization skills as we needed to decide and sort the tasks we had to do during each week.`
  const title7 = "Four-Month Entrepreneurial Internship - Futurapply"
  const title1 = "Internship 2023 IRIT Laboratory"
  const ptext1 = `In 2022, I completed an internship at the IRIT research center in Toulouse, where I focused on Safety Critical Embedded Systems under the supervision of Mme OUERDERNI Meriem. Over six weeks, I researched and analyzed scientific articles to create a state-of-the-art document on the main theme of my internship. 
  I learned the fundamentals of the domain and practiced by creating specifications and models of a numerical clock. A report of this internship can be found by clicking one of the buttons below.`
  const title2 = "Internship 2017 Husky Injection Molding Systems"
  const ptext2 = `In 2017, I completed a two-week internship at Husky Injection Molding Systems in Luxembourg, working in the finance and legal department. During my time there, I gained exposure to an international 
  environment and was responsible for verifying customer payments prior to product delivery. Additionally, I had the opportunity to attend several meetings conducted in English and practice my language skills in both German and English. Finally, I discovered how the company manufactured their products by visiting their factory.`
  const style = {padding:"20px",margin:"20px 70px" , fontSize:"1.2rem",borderRadius:"5px",border:"none"}
  const btn =
    <div>
      <a href={internReportEn} download="English_Report_CORBELLARI_Nolan" rel="noreferrer">      <button style={style} >Download in English</button> </a>
      <a href={internReportFr} download="French_Report_CORBELLARI_Nolan" rel="noreferrer"><button style={style} >Download in French</button></a>      
    </div>
  
  const title3 = "Engineering school in computer science ENSEEIHT 2022-2025"
  const p3 = 
  `I'm currently in my third year at ENSEEIHT a computer science engineering school situated in Toulouse in France. This year I'm studying intensively cybersecurity in the TLS-Sec section.I integrated ENSSEEIHT 2022 after passing the entrance exams. I chose the software development section during my second year in which I learn concepts of software development.
  `
  const title4 = "Top French engineering schools entrance exams preparatory class 2020-2022"
  const p4 =
  `
  I enrolled 2020 in a mathematics preparatory class for top French engineering schools.In my second year, I've chosen to pursue the computer science option, deepening my understanding and skills in the field of technology and computer science.
  
  This experience has instilled in me the skills of hard work, organization, and perseverance. Through rigorous study and dedication in the preparatory class I learnt to spend my time effectively, endure a heavy workload and achieve all the work on time.
  `
  const title5 = "ABIBAC Section 2017-2020"
  const p5 =
  `
  Between 2017 and 2020, I enrolled in ABIBAC section. ABIBAC is a section dedicated to learning the German language through a heavy workload. At the end of the cursus ABIBAC delivers not only the french baccalaureate but also the german equivalent.
  
  
  This section taught me the importance of speaking multiple languages and to have different cultures within a work environment.
  `


  return (
    <MainLayout>
      <Title>Professional background</Title>
      <SectionRight img={Img3} title={title7} ptext={ptext7}/>
      <Section img={Img} title={title1} ptext={ptext1} content={btn}/>
      <SectionRight img={Img2} title={title2} ptext={ptext2}/>
      <Title>Educationnal Background</Title>
      <SectionWhite title={title3} ptext={p3} />
      <SectionBlack title={title4} ptext={p4} />
      <SectionWhite title={title5} ptext={p5} />




    </MainLayout>
  )
}

export default CareerDev
