import React from 'react'
import "./SectionBlack.css"

function SectionBlack(props) {
  return (
    <>
    <div className='sectionBlack-container'>
      <div className='sectionBlack-textcontainer'>
        <h1 id='sectionBlack-title'>{props.title}</h1>
        <p id='sectionBlack-ptext'>{props.ptext}</p>
      </div>
      <img id='sectionBlack-img' loading='lazy' src={props.img}></img>
    </div>
    <div style={{overflow:"hidden"}} id="sectionBlack-container-container-end">
    <div className='sectionBlack-container-end'><></></div>
    </div>
    </>
  )
}

export default SectionBlack