import React from 'react'
import "./SectionRight.css"

function SectionRight(props) {
  return (
    <div className='rightSection-container-container'>
    <div className='rightSection-container'>
      <img id='rightSection-img' loading='lazy' src={props.img}></img>
      <div className='rightSection-text'>
      <h1 id='rightSection-title'>{props.title}</h1>
      <p id='rightSection-ptext'> {props.ptext}</p>
      </div>
    </div>
    </div>
  )
}

export default SectionRight