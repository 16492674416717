import React from 'react'
import "./Section.css"

function Section(props) {
  return (
    <div className='leftSection-container'>
      <div className='leftSection-text'>
      <h1 id='leftSection-title'>{props.title}</h1>
      <p id='leftSection-ptext'>{props.ptext}</p>
      {props.content}
      </div>
      <img id='leftSection-img' loading='lazy' src={props.img}></img>
    </div>
  )
}

export default Section